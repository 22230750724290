import React, { useState, useEffect } from "react"
import LayoutRegular from "../components/layoutRegular"
import StyledFullBackground from "../components/backgroundImage"
import sanitizeHtml from "sanitize-html"
import SEO from "../components/seo"

const pageSEO = {title: "Concerts", description: "Benjamin Engelis concert dates and location information of his upcoming events"}

const Concerts = (props) => {
  // Client-side Runtime Data Fetching
  const [concertsData, setConcertsData] = useState('<p class="loading">Loading...</p>')
  useEffect(() => {

    var fetchUrl = props.location.origin + "/concerts_data.json"
    // console.log("Identified current location as: " + props.location.origin)
    // console.log("Getting URL: " + fetchUrl)

    // get concerts data from .json file
    fetch(`${fetchUrl}`) // actual: https://dev11.lacreme.digital/concerts_data.json // local test/dummy: ../concerts_data_dummy.json
      .then(response => response.json()) // parse JSON from request
      .then(resultData => {
        // console.log("fetched data: " + resultData.value)
        setConcertsData(resultData.value)
      })
  })


  /* sanitize-html default options */
  const options = {
    allowedTags: [ 'h3', 'h4', 'h5', 'h6', 'blockquote', 'p', 'a', 'ul', 'ol',
      'nl', 'li', 'b', 'i', 'strong', 'em', 'strike', 'abbr', 'code', 'hr', 'br', 'div',
      'table', 'thead', 'caption', 'tbody', 'tr', 'th', 'td', 'pre', 'iframe' ],
    disallowedTagsMode: 'discard',
    allowedAttributes: {
      a: [ 'href', 'name', 'target' ],
      p: [ 'class' ],
      // We don't currently allow img itself by default, but this
      // would make sense if we did. You could add srcset here,
      // and if you do the URL is checked for safety
      img: [ 'src' ]
    },
    // Lots of these won't come up by default because we don't allow them
    selfClosing: [ 'img', 'br', 'hr', 'area', 'base', 'basefont', 'input', 'link', 'meta' ],
    // URL schemes we permit
    allowedSchemes: [ 'http', 'https', 'ftp', 'mailto' ],
    allowedSchemesByTag: {},
    allowedSchemesAppliedToAttributes: [ 'href', 'src', 'cite' ],
    allowProtocolRelative: true,
    enforceHtmlBoundary: false
  }

  return (
    <StyledFullBackground imgName="piano">
    <LayoutRegular>
      <div className="concerts-container">
        <h1>Concerts</h1>
        <div className="divider"></div>
        <SEO title={pageSEO.title} description={pageSEO.description} />
        <div dangerouslySetInnerHTML={{ __html: sanitizeHtml(concertsData, options) }} >
        </div>


        {/* <p><strong>June, 2020, </strong>20:45<br />Duo concert with <a href="https://www.dicasola.ch" target="_blank" rel="noreferrer">Fabio Di Casola</a><br />Sils, Offene Kirche</p>
        <p className="text"><strong>June, 2020, </strong>20:45<br />
        Duo concert with <a className="s-link" href="https://www.dicasola.ch" target="_blank" rel="noreferrer">Fabio Di Casola</a><br />
        Sils, Offene Kirche
        </p>
        <p className="text"><strong>June, 2020, </strong>20:45<br />
        Duo concert with <a className="s-link" href="https://www.dicasola.ch" target="_blank" rel="noreferrer">Fabio Di Casola</a><br />
        Sils, Offene Kirche
        </p>
        <p className="text"><strong>June, 2020, </strong>20:45<br />
        Duo concert with <a className="s-link" href="https://www.dicasola.ch" target="_blank" rel="noreferrer">Fabio Di Casola</a><br />
        Sils, Offene Kirche
        </p>
      */} 
      </div>
    </LayoutRegular>
    </StyledFullBackground>
  )
}

export default Concerts